import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// includes
import AlertBar from "elements/alertBar";
import VerifyModal from "elements/modalNotVerified";
import { api, common } from "helpers";
import { config } from "../../helpers/config";
import FireBase from "../../helpers/firebase";
export default function SignIn() {
  // init
  const navigate = useNavigate();

  // const analytics = getAnalytics(app);
  // state
  const [loading, setloading] = useState(false);
  const [isView, setIsView] = useState(false);
  const [localData] = useState(common.localDataGet());

  const [isModal, setIsModal] = useState(null);
  const [error, setError] = useState({ email: "", password: "" });
  const [deviceToken, setDeviceToken] = useState(null);

  // form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        // "email#Please enter a valid email address",
      ],
    },
    password: {
      validate: [
        "req#Password is required!",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    FireBase.getDeviceToken(setDeviceToken);
    common.tempDataRemove();
  }, []);

  // handler
  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      Login();
    }
  };

  // Api
  const Login = () => {
    setloading(true);
    let payload = fields;
    payload["webDevToken"] = deviceToken;
    if (config.type === "AGENT") {
      payload["userType"] = "AGENT";
    } else {
      payload["userType"] = "STUDENT";
    }
    // api call
    let data = {
      url: "ids_login",
      method: "POST",
      auth: "none",
      body: payload,
    };
    api.call(data, (res) => {
      // return
      if (res.status === 200) {
        let data = res.data;
        if (data.role === "student") {
          common.localSet("authData", common.crypt(data, true));
          navigate("/student/home");
        } else if (data.role === "sub_agent") {
          common.localSet("authData", common.crypt(data, true));
          navigate("/agent/home");
        } else {
          let page = null;
          let planId = null;
          if (!data.businessContactNo && !data?.businessName) {
            page = 3;
          }
          // else if (
          //   data.businessRegDocs.length === 0
          //&&
          //data.proofIdentityDocs.length === 0
          // ) {
          //   page = 4;
          // }
          else if (!data?.payments) {
            page = 5;
          } else if (!data?.agreement) {
            page = 7;
          }
          if (page) {
            common.tempDataSet({
              page,
              planId,
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
            });
            navigate("/register/agent/");
          } else {
            common.localSet("authData", common.crypt(data, true));
            navigate("/agent/home");
          }
        }
      } else if (res.status === 500) {
        if (res.data.code === "incorrect_password") {
          setError((prev) => ({ ...prev, password: res.data.error }));
        } else {
          setError((prev) => ({ ...prev, email: res.data.error }));
        }
        setloading(false);
      } else {
        common.notify("E", "Something went wrong, please try again");
      }
      setloading(false);
    });
  };

  // support
  const resetError = (name) => {
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <>
      <div className="auth-wrap promoter-wrap">
        <div className="auth-logo-wrap">
          <a href="/">
            <img
              src={common.loadImg("logo-web.svg")}
              width="115"
              height="57"
              alt="study-uk"
            />
          </a>
        </div>
        <div className="signin-right">
          <div className="inner-wrap">
            {/* <div className="inner-left">
              <img
                src={common.loadImg("white-cloud.svg")}
                width="126"
                height="43"
                alt="cloud"
                className="left-cloud"
              />
            </div> */}
            <div className="w-100">
              <p className="ms-5 ps-5">
                <img
                  src={common.loadImg("white-cloud.svg")}
                  width="126"
                  height="43"
                  alt="cloud"
                  className="ms-5"
                />
              </p>
              <h2 className="signin-content-title">
                Discover a
                <img
                  src={common.loadImg("blue-cloud-sm.svg")}
                  width="45"
                  height="26"
                  alt="cloud"
                  className="ms-5"
                />
                <br />
                healthy
                <span className="ps-2">
                  second <br /> income
                </span>
                <br />
                opportunity.
              </h2>
              <p>
                <img
                  src={common.loadImg("blue-cloud.svg")}
                  width="111"
                  height="40"
                  alt="cloud"
                  className="ms-4"
                />
              </p>
              <div className="search-wrap">
                <div className="search-left">
                  <img
                    src={common.loadImg("search-icn.png")}
                    width="40"
                    height="40"
                    alt="search-icn"
                    className=""
                  />
                </div>
                <div className="search-right">
                  <p className="mb-0">
                    Become a promoter for one of our University Degree
                    programmes!
                  </p>
                </div>
              </div>
              <p className="signin-foot-txt">
                Earn over £1300 per student you refer <br /> for example, with
                10 students; <br /> <span>you could earn £13,000!</span>
              </p>
            </div>
            <img
              src={common.loadImg("promoter-signin-bg.svg")}
              width="388"
              height="594"
              alt="search-icn"
              className="promoter-signin-bg"
            />
          </div>
        </div>

        <div className="left">
          <div className="auth-form-container">
            {/* <p className="title">
              <span>Sign in as Promoter</span>
            </p> */}
            <div>
              <div className="form-wrap auth-form-wrap">
                {/* <One onSuccess={() => setStep(2)} /> */}
                <div className="auth-form-header">
                  <a href="/login" className="auth-form-btn active">
                    Sign In
                  </a>
                  <a
                    href={
                      config.type === "STUDENT"
                        ? "/"
                        : "/register/agent"
                    }
                    className="auth-form-btn"
                  >
                    Register
                  </a>
                </div>
                <div>
                  <div className="et-form-row form-wrap">
                    <input
                      type="email"
                      name="email"
                      placeholder="Username or Email"
                      className="form-control input-email cust-input"
                      maxLength={40}
                      value={fields.email}
                      onChange={(e) => {
                        resetError("email");
                        handleChange(e);
                      }}
                    />
                    <div className="email-fieldcheckbox checkbox-design">
                      <div className={"input-tick-active"}></div>
                    </div>
                    <p className="error-txt">
                      {error.email ? error.email : errors.email}
                    </p>
                  </div>
                  <div className="et-form-row">
                    <input
                      type={isView ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      className="form-control cust-input"
                      maxLength={15}
                      onChange={(e) => {
                        resetError("password");
                        handleChange(e);
                      }}
                      value={fields.password}
                    />
                    <div className=" email-fieldcheckbox checkbox-design">
                      <div className="input-tick-active"></div>
                    </div>
                    <div className="pwd-eye" onClick={() => setIsView(!isView)}>
                      <img
                        src={common.loadImg(
                          isView ? "password_show.svg" : "password_hide.svg"
                        )}
                        alt="show"
                        className="et-cp"
                      />
                    </div>
                    <p className="error-txt">
                      {error.password ? error.password : errors.password}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex">
                      {/* <div className="checkbox-design d-flex me-3">
                      <input
                        id="rememberLogin"
                        className="me-3"
                        type="checkbox"
                        name="rememberme"
                      />
                      <label
                        className="rememberlogin-text et-cp"
                        htmlFor="rememberLogin"
                      >
                        Remember Me
                      </label>
                    </div> */}
                    </div>
                    <div>
                      <Link
                        to="/password/forgot"
                        className="rememberlogin-text new-forgot-pwd"
                      >
                        Forgot Password
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className="form-btn promoter-auth-btn w-100"
                      disabled={loading}
                      onClick={onSubmit}
                    >
                      {loading ? "Processing" : "Sign In"}
                    </button>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-between mt-2">
                    <p className="mb-0" style={{ color: "#fff" }}>
                      Don't have on account
                    </p>
                    <Link
                      to="/register/agent"
                      className="rememberlogin-text new-forgot-pwd"
                    >
                      Register
                    </Link>
                  </div> */}
                  {/* <div className="et-page-wrap-border mt-4 mb-3">
                  <div className="d-flex justify-content-center rememberlogin-text mt-3">
                    Don’t have an account ?
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="mt-3 mb-2 signin-btn-wrap">
                    <Link to="/" className="me-3">
                      <button className="et-btn-outline btn-reg">
                        Register
                      </button>
                    </Link>
                  </div>
                </div> */}
                </div>
              </div>
              <p className="policy-txt">
                We never share your data without permission. <br />
                Our privacy and cookie policies
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import AmlHashForm from "aml-hash-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
//includes
import { common } from "helpers";
import { api } from "helpers/api";
import { config } from "../../helpers/config";

function ForgotPassword() {
  // init
  const navigate = useNavigate();
  //state
  const [localData] = useState(common.localDataGet());
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        // "email#Please enter a valid email address",
      ],
      value: localData?.email,
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  //api
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      otpGenerate();
    }
  };

  //api
  const otpGenerate = () => {
    setLoading(true);
    let payload = fields;
    if (config.type === "AGENT") {
      payload["userType"] = "AGENT";
    } else {
      payload["userType"] = "STUDENT";
    }
    let data = {
      url: "ids_forgot_password",
      method: "POST",
      auth: "none",
      body: payload,
    };
    api.call(data, (res) => {
      if (res.status === 500) {
        setError(res.data.error);
      } else if (res.status === 200) {
        common.localDataSet({
          email: fields.email,
          type: "passwordForgot",
        });
        navigate("/otp/verify");
      }
      setLoading(false);
    });
  };

  //render
  return (
    <>
      {/* <div className="container-fluid content-wrap">
        <div className="row mt-5 mx-2">
          <div className="col-md-5 mt-4 mx-auto">
            <div className="box-header">
              <p className="title">Forgot Password</p>
            </div>
            <div className="box-body">
              <label className="form-label">Email Address</label>
              <div className="et-form-row">
                <input
                  type="email"
                  name="email"
                  className="form-control input-email"
                  value={fields.email}
                  placeholder="Enter your email address"
                  onChange={(e) => {
                    setError(null);
                    handleChange(e);
                  }}
                />
                <div className=" email-fieldcheckbox checkbox-design">
                  <div className="input-tick"></div>
                </div>
                <p className="error-txt">{error ? error : errors.email}</p>
              </div>

              <div className="d-flex justify-content-center mt-4">
                <button
                  className="btn-default"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Submit"}
                </button>
              </div>
            </div>
            <div className="shadow-div"></div>
            <div className="greenshow-div"></div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid p-0">
        <div className="auth-wrap promoter-wrap auth-signup ">
          <div className="signup-right">
            <div className="inner-wrapper">
              <h2 className="title mb-0">No false promises</h2>
              <h1 className="title sub mb-0">Realistic Income</h1>
              <p>
                Believe in yourself to <br /> Achieve what is possible for you!
              </p>
              <div className="px-5">
                <img
                  src={common.loadImg("weight-down.svg")}
                  width="368"
                  height="343"
                  alt="weight1"
                  className="weight-down-img"
                />
              </div>
              <img
                src={common.loadImg("weight-up.svg")}
                width="397"
                height="688"
                alt="weight1"
                className="weight-up-img"
              />
            </div>
            <div className="agent-signup-right">
              <img
                src={common.loadImg("logo-web.svg")}
                width="115"
                height="57"
                alt="study-uk"
              />
            </div>
            <div>
              <p className="promoter-siginup-foot-txt">
                Register as a Student for Pickauni and earn a{" "}
                <strong>realistic £50 - £100k per year.*</strong>
              </p>
              <p className="more-link-txt">
                Click the link below to find out more
              </p>
            </div>
          </div>
          <div className="bottom-bg"></div>
          <div className="left">
            <p className="title register-title mb-5">Forgot Password</p>
            {/* <div className="d-flex justify-content-start mb-5">
                {Array(6)
                  .fill()
                  .map((i, k) => {
                    ++k;
                    return (
                      <div key={k} className="cp" onClick={() => gotoStep(k)}>
                        <p className="register-steps-label web-steps-label me-2">
                          <span
                            className={`dotbtn${
                              step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                            }`}
                            data-title={tooltip[k - 1]}
                          >
                            {k}
                          </span>
                          <span
                            className={`reg-steps-label${
                              step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                            }`}
                          >
                            {tooltip[k - 1]}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                <p
                  id="tool-tip"
                  className="et-cp"
                  data-tooltip-content="This is Tooltip"
                ></p>
                <ReactTooltip
                  anchorId="tool-tip"
                  place={"bottom"}
                  style={{ zIndex: "999" }}
                />
              </div> */}
            <div style={{ minHeight: "40px" }}></div>
            <div className="form-wrap auth-form-wrap">
              <div className="auth-form-container">
                <div className="text-center pb-3">
                  <div className="et-form-row form-wrap">
                    {/* <input
                      type="email"
                      name="email"
                      placeholder="Username or Email"
                      className="form-control input-email cust-input"
                      maxLength={40}
                      value={fields.email}
                      onChange={(e) => {
                        resetError("email");
                        handleChange(e);
                      }}
                    />
                    <div className="email-fieldcheckbox checkbox-design">
                      <div className={"input-tick-active"}></div>
                    </div>
                    <p className="error-txt">{error ? error : errors.email}</p> */}
                    <input
                      type="email"
                      name="email"
                      className="form-control input-email cust-input"
                      value={fields.email}
                      placeholder="Enter your email address"
                      onChange={(e) => {
                        setError(null);
                        handleChange(e);
                      }}
                    />
                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className={
                          loading
                            ? "form-control form-btn promoter-auth-btn"
                            : "form-control form-btn promoter-auth-btn"
                        }
                        onClick={onSubmit}
                        disabled={loading}
                      >
                        {loading ? "Processing..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
                <p className="error-txt">{error ? error : errors.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;

import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//includes
import { api, common } from "helpers";

function ResetPassword() {
  // init
  const navigate = useNavigate();
  //state
  const [init, setInit] = useState(false);
  const [localData] = useState(common.localDataGet());
  const [viewA, SetViewA] = useState(false);
  const [viewB, SetViewB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // effect
  useEffect(() => {
    if (!localData) {
      navigate("/login");
    } else {
      setInit(true);
    }
  }, []);

  // form
  const formFields = {
    password: {
      validate: [
        "req#Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Password and confirm password do not match",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      setLoading(true);
      //api
      let data = {
        url: "ids_otp_reset_password",
        method: "POST",
        auth: "none",
        body: {
          otp: localData.otp,
          password: fields.password,
          confirmPassword: fields.confirmPassword,
        },
      };
      api.call(data, (response) => {
        if (response.status === 200) {
          common.localDataSet({ alert: "Password has been reset" });
          navigate("/login");
        } else if (response.status === 500) {
          setError(response.data.error);
        }
        setLoading(false);
      });
    }
  };

  return (
    init && (
      <>
        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-12 et-page-wrap otp-page-wrap">
              <div className="row">
                <div className="col-md-5 mx-auto">
                  <div className="box-header">
                    <p className="title">Reset Password</p>
                  </div>
                  <div className="box-body">
                    <label className="form-label">New Password</label>
                    <div className="et-form-row">
                      <input
                        type={viewA ? "text" : "password"}
                        name="password"
                        maxLength={15}
                        className="form-control"
                        onChange={handleChange}
                        value={fields.password}
                      />
                      <div className=" email-fieldcheckbox checkbox-design">
                        <div className="input-tick "></div>
                      </div>
                      <div
                        className="pwd-eye"
                        onClick={() => SetViewA((prev) => !prev)}
                      >
                        {viewA ? (
                          <img
                            src={common.loadImg("password_show.svg")}
                            alt="show"
                            className="et-cp"
                          />
                        ) : (
                          <img
                            src={common.loadImg("password_hide.svg")}
                            alt="hide"
                            className="et-cp"
                          />
                        )}
                      </div>
                      <p className="error-txt">{error ?? errors.password}</p>
                    </div>
                    <label className="form-label mt-3">Confirm Password</label>
                    <div className="et-form-row">
                      <input
                        type={viewB ? "text" : "password"}
                        name="confirmPassword"
                        maxLength={15}
                        className="form-control"
                        onChange={handleChange}
                        value={fields.confirmPassword}
                      />

                      <div className=" email-fieldcheckbox checkbox-design">
                        <div className="input-tick"></div>
                      </div>
                      <div
                        className="pwd-eye"
                        onClick={() => SetViewB((prev) => !prev)}
                      >
                        {viewB ? (
                          <img
                            src={common.loadImg("password_show.svg")}
                            alt="show"
                            className="et-cp"
                          />
                        ) : (
                          <img
                            src={common.loadImg("password_hide.svg")}
                            alt="hide"
                            className="et-cp"
                          />
                        )}
                      </div>
                      <p style={{ color: "red" }}>{errors.confirmPassword}</p>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className="btn-default"
                        disabled={loading}
                        onClick={onSubmit}
                      >
                        {loading ? "Processing..." : "Submit"}
                      </button>
                    </div>
                  </div>

                  <div className="shadow-div"></div>
                  <div className="greenshow-div"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container-fluid p-0">
          <div className="auth-wrap promoter-wrap auth-signup ">
            <div className="signup-right">
              <div className="inner-wrapper">
                <h2 className="title mb-0">No false promises</h2>
                <h1 className="title sub mb-0">Realistic Income</h1>
                <p>
                  Believe in yourself to <br /> Achieve what is possible for
                  you!
                </p>
                <div className="px-5">
                  <img
                    src={common.loadImg("weight-down.svg")}
                    width="368"
                    height="343"
                    alt="weight1"
                    className="weight-down-img"
                  />
                </div>
                <img
                  src={common.loadImg("weight-up.svg")}
                  width="397"
                  height="688"
                  alt="weight1"
                  className="weight-up-img"
                />
              </div>
              <div className="agent-signup-right">
                <img
                  src={common.loadImg("logo-web.svg")}
                  width="115"
                  height="57"
                  alt="study-uk"
                />
              </div>
              <div>
                <p className="promoter-siginup-foot-txt">
                  Register as a Student for Pickauni and earn a{" "}
                  <strong>realistic £50 - £100k per year.*</strong>
                </p>
                <p className="more-link-txt">
                  Click the link below to find out more
                </p>
              </div>
            </div>
            <div className="bottom-bg"></div>
            <div className="left">
              <p className="title register-title mb-5">Reset Password</p>
              <div style={{ minHeight: "40px" }}></div>
              <div className="form-wrap auth-form-wrap">
                <div className="auth-form-container">
                  <div className="text-center pb-3">
                    <div className="et-form-row form-wrap">
                      {/* <input
                        type="email"
                        name="email"
                        className="form-control input-email cust-input"
                        value={fields.email}
                        placeholder="Enter your email address"
                        onChange={(e) => {
                          setError(null);
                          handleChange(e);
                        }}
                      />
                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className={
                            loading
                              ? "form-control form-btn promoter-auth-btn"
                              : "form-control form-btn promoter-auth-btn"
                          }
                          onClick={onSubmit}
                          disabled={loading}
                        >
                          {loading ? "Processing..." : "Submit"}
                        </button>
                      </div> */}
                      <input
                        type={viewA ? "text" : "password"}
                        name="password"
                        maxLength={15}
                        className="form-control input-email cust-input"
                        placeholder="New Password"
                        onChange={handleChange}
                        value={fields.password}
                      />
                      <div className=" email-fieldcheckbox checkbox-design">
                        <div className="input-tick "></div>
                      </div>
                      <div
                        className="pwd-eye"
                        onClick={() => SetViewA((prev) => !prev)}
                      >
                        {viewA ? (
                          <img
                            src={common.loadImg("password_show.svg")}
                            alt="show"
                            className="et-cp"
                          />
                        ) : (
                          <img
                            src={common.loadImg("password_hide.svg")}
                            alt="hide"
                            className="et-cp"
                          />
                        )}
                      </div>
                      <p className="error-txt">{error ?? errors.password}</p>
                    </div>
                    <div className="et-form-row form-wrap">
                      <input
                        type={viewB ? "text" : "password"}
                        name="confirmPassword"
                        maxLength={15}
                        className="form-control input-email cust-input"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        value={fields.confirmPassword}
                      />

                      <div className=" email-fieldcheckbox checkbox-design">
                        <div className="input-tick"></div>
                      </div>
                      <div
                        className="pwd-eye"
                        onClick={() => SetViewB((prev) => !prev)}
                      >
                        {viewB ? (
                          <img
                            src={common.loadImg("password_show.svg")}
                            alt="show"
                            className="et-cp"
                          />
                        ) : (
                          <img
                            src={common.loadImg("password_hide.svg")}
                            alt="hide"
                            className="et-cp"
                          />
                        )}
                      </div>
                      <p style={{ color: "red" }}>{errors.confirmPassword}</p>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className={
                          loading
                            ? "form-control form-btn promoter-auth-btn"
                            : "form-control form-btn promoter-auth-btn"
                        }
                        disabled={loading}
                        onClick={onSubmit}
                      >
                        {loading ? "Processing..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default ResetPassword;

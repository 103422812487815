import moment from "moment-timezone";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
// includes
import { api, common } from "helpers";

export default function Otp() {
  // init
  const navigate = useNavigate();

  // state
  const [init, setInit] = useState(false);
  const [localData] = useState(common.localDataGet());
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [timeOut, setTimeOut] = useState(59);
  const [loading, setLoading] = useState(false);

  // effect
  useEffect(() => {
    if (!localData) {
      navigate("/login");
    } else {
      setInit(true);
    }
  }, []);

  useEffect(() => {
    const timer =
      timeOut > 0 && setInterval(() => setTimeOut(timeOut - 1), 1000);
    return () => clearInterval(timer);
  }, [timeOut]);

  // api
  const onSubmit = () => {
    if (otp.length < 6) {
      setError("Enter the otp");
    } else {
      setLoading(true);
      // call
      let data =
        localData.type === "passwordForgot"
          ? {
              url: "ids_otp_reset",
              method: "POST",
              auth: "none",
              body: {
                email: localData.email,
                otp: otp,
                timeZone: moment.tz.guess(),
              },
            }
          : {
              url: "ids_otp_verify",
              method: "POST",
              auth: "none",
              body: {
                otp,
              },
            };

      api.call(data, (res) => {
        if (res.status === 200) {
          let data = res.data;
          if (localData.type === "passwordForgot") {
            common.localDataSet({
              email: localData.email,
              // resetToken: data.resetToken,
              otp,
            });
            navigate("/password/reset");
          } else if (localData.type === "studentRegister") {
            common.notify("S", "Registration has been successfully completed");
            common.localSet("authData", common.crypt(data, true));
            navigate("/student/home");
          }
        } else if (res.status === 500) {
          setError(res.data.error);
        }
        setLoading(false);
      });
    }
  };

  const otpGenerator = () => {
    let data =
      localData.type === "passwordForgot"
        ? {
            url: "auth_otp_verify",
            method: "POST",
            auth: "none",
            body: {
              email: localData.email,
            },
          }
        : {
            url: "ids_sign_up",
            method: "POST",
            auth: "none",
            body: {
              ...localData,
            },
          };
    api.call(data, (res) => {
      if (res.status === 200) {
        setTimeOut(59);
        common.notify("S", "Otp has been sent to " + localData.email);
      } else if (res.status === 500) {
        setError(res.data.error);
      }
    });
  };

  // support
  const goBack = (e) => {
    e.preventDefault();
    if (localData.type === "passwordForgot") {
      common.localDataSet({
        email: localData.email,
      });
    } else if (localData.type === "studentRegister") {
      common.localDataSet(localData);
    }
    navigate(-1);
  };

  // render
  return (
    init && (
      <>
        {/* <div className="container-fluid">
        <div className="row">
          <div className="col-12 et-page-wrap otp-page-wrap">
            <div className="row">
              <div className="col-md-5 mx-auto">
                <div className="box-header">
                  <p className="title">OTP Verification</p>
                </div>
                <div className="otp-body">
                  <div>
                    <label className="form-label">
                      OTP sent to <b>{localData?.email}</b>
                    </label>
                  </div>
                  <div className="otp-section d-flex justify-content-center">
                    <OtpInput
                      value={otp}
                      onChange={(value) => {
                        setError(null);
                        setOtp(value);
                      }}
                      numInputs={6}
                      isInputNum={true}
                      className={error && "otp-error"}
                      inputStyle={{
                        width: "54px",
                        height: "54px",
                        color: "#000",
                        fontWeight: "400",
                        fontSize: "22px",
                        caretColor: "blue",
                      }}
                      separator={<span>-</span>}
                    />
                  </div>
                  <div className="mx-5">
                    <p className="error-txt">{error}</p>
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <div className="d-flex">
                      <p className="rememberlogin-text">Didn't receive code?</p>
                    </div>
                    <div>
                      {timeOut ? (
                        <p>Resend OTP in 0:{timeOut} </p>
                      ) : (
                        <a
                          onClick={otpGenerator}
                          className="rememberlogin-text"
                        >
                          Resend code
                        </a>
                      )}
                    </div>
                  </div>

                  <div className=" justify-content-between mt-3">
                    <div>
                      <a
                        href="#"
                        className="rememberlogin-text"
                        onClick={goBack}
                      >
                        Back
                      </a>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className={loading ? "btn-signin-disable" : "btn-signin"}
                      disabled={loading ? loading : false}
                      onClick={onSubmit}
                    >
                      {loading ? "Processing" : "Submit"}
                    </button>
                  </div>
                </div>

                <div className="shadow-div"></div>
                <div className="greenshow-div"></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        <div className="container-fluid p-0">
          <div className="auth-wrap promoter-wrap auth-signup ">
            <div className="signup-right">
              <div className="inner-wrapper">
                <h2 className="title mb-0">No false promises</h2>
                <h1 className="title sub mb-0">Realistic Income</h1>
                <p>
                  Believe in yourself to <br /> Achieve what is possible for
                  you!
                </p>
                <div className="px-5">
                  <img
                    src={common.loadImg("weight-down.svg")}
                    width="368"
                    height="343"
                    alt="weight1"
                    className="weight-down-img"
                  />
                </div>
                <img
                  src={common.loadImg("weight-up.svg")}
                  width="397"
                  height="688"
                  alt="weight1"
                  className="weight-up-img"
                />
              </div>
              <div className="agent-signup-right">
                <img
                  src={common.loadImg("logo-web.svg")}
                  width="115"
                  height="57"
                  alt="study-uk"
                />
              </div>
              <div>
                <p className="promoter-siginup-foot-txt">
                  Register as a Student for Pickauni and earn a{" "}
                  <strong>realistic £50 - £100k per year.*</strong>
                </p>
                <p className="more-link-txt">
                  Click the link below to find out more
                </p>
              </div>
            </div>
            <div className="bottom-bg"></div>
            <div className="left">
              <p className="title register-title mb-5">OTP Verification</p>
              {/* <div className="d-flex justify-content-start mb-5">
                {Array(6)
                  .fill()
                  .map((i, k) => {
                    ++k;
                    return (
                      <div key={k} className="cp" onClick={() => gotoStep(k)}>
                        <p className="register-steps-label web-steps-label me-2">
                          <span
                            className={`dotbtn${
                              step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                            }`}
                            data-title={tooltip[k - 1]}
                          >
                            {k}
                          </span>
                          <span
                            className={`reg-steps-label${
                              step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                            }`}
                          >
                            {tooltip[k - 1]}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                <p
                  id="tool-tip"
                  className="et-cp"
                  data-tooltip-content="This is Tooltip"
                ></p>
                <ReactTooltip
                  anchorId="tool-tip"
                  place={"bottom"}
                  style={{ zIndex: "999" }}
                />
              </div> */}
              <div className="form-wrap auth-form-wrap">
                <div className="auth-form-container mt-5">
                  <div className="text-center pb-3">
                    <label className="auth-otp-label">
                      OTP sent to your <strong>{localData?.email}</strong>
                    </label>
                  </div>
                  <div className="otp-section d-flex justify-content-center">
                    <OtpInput
                      value={otp}
                      onChange={(value) => {
                        setError(null);
                        setOtp(value);
                      }}
                      numInputs={6}
                      isInputNum={true}
                      className={error && "otp-error"}
                      inputStyle={{
                        width: "60px",
                        height: "74px",
                        color: "#000",
                        fontWeight: "400",
                        fontSize: "22px",
                        caretColor: "blue",
                        borderRadius: "10px",
                      }}
                      separator={<span>-</span>}
                    />
                  </div>
                  <div className="mx-5">
                    <p className="error-txt">{error}</p>
                  </div>

                  <div className="d-flex justify-content-between mt-4">
                    <div className="d-flex">
                      <p className="auth-otp-label">Didn't receive code?</p>
                    </div>
                    <div>
                      {timeOut ? (
                        <p className="auth-otp-label">
                          Resend OTP in 0:{timeOut}{" "}
                        </p>
                      ) : (
                        <a onClick={otpGenerator} className="auth-otp-label cp">
                          Resend OTP
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className={
                        loading
                          ? "form-control form-btn promoter-auth-btn"
                          : "form-control form-btn promoter-auth-btn"
                      }
                      disabled={loading ? loading : false}
                      onClick={onSubmit}
                    >
                      {loading ? "Processing" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

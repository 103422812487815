import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
// includes
import { api, common, types } from "helpers";
import StatusBar from "elements/statusBar";
import Select from "react-select";

function Form() {
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  const [user, setUser] = useState({});
  // state
  const [status, setStatus] = useState(id ? "process" : "success");
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState("");
  const [permission, setPermission] = useState([{
    "stuView": "stumy",
    "stuUpdate": "stumy",
    "appView": "appmy",
    "appUpdate": "appmy"
  }]);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  // validate
  const formFields = {
    firstName: { validate: ["req"] },
    lastName: { validate: ["req"] },
    email: { validate: ["req", "email"] },
    password: {
      validate: ["req", "min:8", "max:15"],
      // value: common.generateString(8),
    },
    // isActive: { value: types.status[0] }
    handlingAgent: {},
  };  const { fields, errors, handleChange, handleSubmit, setMultiValue } =
    AmlHashForm(formFields);

    useEffect(() => {
      getAgents();
      getUser();
    }, []);
  
    const getUser = () => {
      if (authInfo?.role !== "student") {
        api.call(
          {
            url: "agent_profile",
            method: "GET",
          },
          (res) => {
            if (res.status === 200) {
              setUser(res.data);
            }
          }
        );
      }
    };

    const getAgents = () => {
      let data = {
        url: "agents",
        method: "GET",
        query: "?offset=1&limit=1000",
      };
  
      api.call(data, (res) => {
        if (res.status === 200) {
          let agent = [];
          res?.data?.data?.map(item => {
            let val = {
              label: item.firstName,
              value: item._id
            }
            agent.push(val);
          })
          setAgents(agent)
        }
      });
    };

  // handler
  const handleCheckChange = (val, type) => {
    setPermission((prev) => {
      let nData = [...prev];
      nData.map((element) => {
        element[type] = val;
      });
      return nData;
    });
  };

  const onSubmit = () => {
    let valid = handleSubmit();
    if (valid) {
      let handleAgents = [];
      selectedAgents.length > 0 && selectedAgents.map(item => {
        handleAgents.push(item.value);
      })
      setLoading(true);
      let data = {};
      let params = { 
        firstName: fields.firstName,
        lastName: fields.lastName,
        // isActive: fields.isActive.value,
        email: fields.email,
        password: fields.password,
        confirmPassword: fields.password,
        permissions: permission,
        handlingAgent: handleAgents
       };
      data = {
        url: "subagent",
        method: "POST",
        body: { ...params },
      };
      api.call(data, (res) => {
        console.log("=======res", res);
        if (res.status === 204) {
          common.notify(
            "S",
            `Member has been created successfully`
          );
          navigate("/agent/member/list");
        } else if (res.status === 400) {
          common.notify("E", res.data.error);
          // setError("Email address already exist");
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(false);
      });
    }
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={common.loadImg("back-icn.svg")}
            className="ed-cp"
            alt=""
            onClick={() => navigate("/agent/member/list")}
          />
          <h1 className="page-title ps-4">{id ? "Edit" : "Add New"} Member</h1>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <div className="container-fluid">
            <h2 className="form-title">Member Info</h2>
            <div className="form-wrap member-edit-wrap">
              <div className="row pb-4 ed-bb">
                <div className="col-md-6 mb-4">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Enter the first name"
                    className="form-control"
                    value={fields.firstName}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.firstName}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Enter the last name"
                    className="form-control"
                    value={fields.lastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Email Address</label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter the email"
                    className="form-control"
                    value={fields.email}
                    onChange={(e) => {
                      handleChange(e);
                      setError("");
                    }}
                  />
                  <p className="error-txt">{errors.email || error}</p>
                </div>
                {!id && (
                  <div className="col-md-6 mb-4">
                    <label>Password</label>
                    <input
                      type="text"
                      name="password"
                      placeholder="Enter the password"
                      className="form-control"
                      value={fields.password}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.password}</p>
                  </div>
                )}
              {authInfo.role == "agent" && user.isPromotor && (
                <div className="col-md-6 mb-4">
                  <label>Agents</label>
                  <Select
                    className="basic-multi-select"
                    value={selectedAgents}
                    options={agents}
                    isMulti
                    // isClearable={true}
                    onChange={(data) => {
                      setSelectedAgents(data);
                    }}
                  />
                </div>
              )}
              </div>
              {/* <div className="col-md-6 mb-4">
                  <label>Status</label>
                  <Select
                    className="custom-select-box"
                    value={fields.isActive}
                    options={types.status}
                    isClearable={false}
                    onChange={(data) => {
                      setMultiValue({ isActive: data });
                    }}
                  />
                </div> */}
              <p className="pt-3">Permissions</p>
              <div className="row pb-4 ed-bb">
                <p className="pt-3">Students</p>
                <div className="col-md-6 mb-4">
                  <label>View</label>
                  <select
                    className="form-select page-per-view"
                    value={permission?.length && permission[0]["stuView"]}
                    onChange={(e) =>
                      handleCheckChange(e.target.value, "stuView")
                    }
                  >
                    <option value="">Select</option>
                    <option value="stumy">My</option>
                    <option value="stuall">All</option>
                  </select>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Update</label>
                  <select
                    className="form-select page-per-view"
                    value={permission?.length && permission[0]["stuUpdate"]}
                    onChange={(e) =>
                      handleCheckChange(e.target.value, "stuUpdate")
                    }
                  >
                    <option value="">Select</option>
                    <option value="stumy">My</option>
                    <option value="stuall">All</option>
                  </select>
                </div>
              </div>
              <div className="row pb-4 ed-bb">
                <p className="pt-3">Applications</p>

                <div className="col-md-6 mb-4">
                  <label>View</label>
                  <select
                    className="form-select page-per-view"
                    value={permission?.length && permission[0]["appView"]}
                    onChange={(e) =>
                      handleCheckChange(e.target.value, "appView")
                    }
                  >
                    <option value="">Select</option>
                    <option value="appmy">My</option>
                    <option value="appall">All</option>
                  </select>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Update</label>
                  <select
                    className="form-select page-per-view"
                    value={permission?.length && permission[0]["appUpdate"]}
                    onChange={(e) =>
                      handleCheckChange(e.target.value, "appUpdate")
                    }
                  >
                    <option value="">Select</option>
                    <option value="appmy">My</option>
                    <option value="appall">All</option>
                  </select>
                </div>
              </div>
              {/* {types.permission.list.map((i, k) => (
                <div key={k}>
                  <label>{types.permission.label[i.key]}</label>
                  <ul className="row permissions-wrap pt-2">
                    {i.types.map((m, n) => (
                      <li className="col-md-4" key={n}>
                        <label className="role-checkbox-wrap">
                          <input
                            type="checkbox"
                            name="read"
                            value={i.key + m}
                            checked={permission.includes(i.key + m)}
                            onChange={handleCheckChange}
                          />
                          <span className="checkmark"></span>
                          {types.permission.label[m]}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              ))} */}
            </div>
            <div className="modal-footer">
              <button
                className="btn ed-btn-default ed-btn-save-dt"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? "Processing..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default Form;

import React, { Fragment, useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
// layouts
import FrontHeader from "./frontHeader";
// includes
import { common } from "helpers";
import { config } from "helpers/config";

const FrontLayout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // state
  const [isAuth] = useState(common.checkAuth());
  const [tempData] = useState(common.tempDataGet());
  // effect
  useEffect(() => {
    if (isAuth) {
      common.redirect(
        common.getAuth()?.role === "student" ? "student/home" : "agent/home"
      );
    }
  }, [isAuth]);
  return (
    !isAuth && (
      <Fragment>
        {location?.pathname !== "/" &&
          location?.pathname !== "/login" &&
          location?.pathname !== "/register/student/direct" &&
          location?.pathname !== "/register/agent" &&
          location?.pathname !== "/otp/verify" &&
          location?.pathname !== "/password/forgot" &&
          location?.pathname !== "/password/reset" && <FrontHeader />}
        <Outlet {...props} />
      </Fragment>
    )
  );
};

export default FrontLayout;
